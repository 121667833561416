import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

// styles
import "../styles/main.scss"

// markup
const PrivacyPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy - Hit Your Steps</title>
        <link rel="canonical" href="https://www.hityoursteps.com/privacy" />
      </Helmet>
      <section class="text">
        <h2>Privacy Policy</h2>
        <p>We (Hit Your Steps) do not collect, use, save, or have access to any of your personal data recorded in Hit Your Steps for iOS.</p>
        <p>Individual settings and step goals are not personal and are stored only on your device.</p>
        <p>You will be asked to provide access to your health data, we don’t process that information at all and have no access to it, it never leaves your device.</p>
        <p>Feel free to contact us about this Privacy policy via email <a href="mailto:info@hityoursteps.com">info@hityoursteps.com</a></p>
      </section>
    </Layout>
  )
}

export default PrivacyPage
